import { Suspense, lazy } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import { esES as LocaleDataGrid } from "@mui/x-data-grid";
import { ApiInterceptor } from "./environment/environment";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import Spinner from "./components/Spinner";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import ComprasTabs from "./pages/Compras/tabs";

Amplify.configure(awsconfig);
const theme = createTheme(esES, LocaleDataGrid);
ApiInterceptor();
const App = () => {
  const Proyectos = lazy(() => import("./pages/Proyectos"));
  const DetalleProyecto = lazy(() => import("./pages//DetalleProyecto"));
  const Login = lazy(() => import("./pages//Login"));
  const Registro = lazy(() => import("./pages//Registro"));
  const CambioContraseña = lazy(() => import("./pages//CambioContraseña"));
  const VerificarCorreo = lazy(() => import("./pages//VerificarCorreo"));
  const Nosotros = lazy(() => import("./pages//Nosotros"));
  const DetalleLote = lazy(() => import("./pages//DetalleLote"));
  const DetalleReserva = lazy(() => import("./pages//DetalleReserva"));
  const ControlPersonal = lazy(() => import("./pages//ControlPersonal"));
  const HistorialPagos = lazy(() => import("./pages/historialPagos"));
  const Reembolsos = lazy(() => import("./pages//Reembolsos"));
  const Header = lazy(() => import("./components//Header//index"));
  const Comisionista = lazy(() => import("./pages//Comisionista"));

  return (
    <Suspense
      fallback={
        <Spinner full>
          <h6>Cargando...</h6>
        </Spinner>
      }
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route element={<Login />} path="/LogIn" exact />
                    <Route
                      element={<VerificarCorreo />}
                      path="/VerificarCorreo"
                      exact
                    />
                    <Route element={<Registro />} path="/RegistroCLiente" exact />
                    <Route
                      element={<CambioContraseña />}
                      path="/CambioContraseña"
                      exact
                    />
                  </Route>
                  <Route
                    element={<Navigate to={"/Proyectos"} />}
                    path="/"
                    exact
                  />
                  <Route element={<Proyectos />} path="/Proyectos" exact />
                  <Route element={<Nosotros />} path="/Nosotros" exact />
                  <Route
                    element={<DetalleProyecto />}
                    path="/DetalleProyecto"
                    exact
                  />
                  <Route element={<Header />} path="/Header" exact />
                  <Route element={<DetalleLote />} path="/DetalleLote" exact />
                  <Route
                    element={<DetalleReserva />}
                    path="/DetalleReserva"
                    exact
                  />
                  <Route
                    element={<Comisionista />}
                    path="/Comisionista"
                    exact
                  />
                  <Route element={<PrivateRoutes />}>
                    <Route
                      element={<ControlPersonal />}
                      path="/ControlPersonal"
                      exact
                    />
                    <Route
                      element={<HistorialPagos />}
                      path="/HistorialPagos"
                      exact
                    />
                    {/* <Route element={<Reembolsos />} path="/Reembolsos" exact /> */}
                    <Route element={<ComprasTabs />} path="/Compras" exact />
                  </Route>
                  <Route
                    element={<Navigate to={"/Proyectos"} />}
                    path="*"
                    exact
                  />
                </Routes>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
};

export default App;
